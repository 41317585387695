import React from 'react';
import styled from "styled-components";
import Spacer from './layout/Spacer';
import Text from './editorial/Text';
import RoundButton from './RoundButton';
import PillButton from './buttons/PillButton';

const Comp = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  // width: 100%;
    // margin-left: ${({ theme }) => theme.margins.small.l};
    // margin-right: ${({ theme }) => theme.margins.small.l};
  padding-left: ${({ theme }) => theme.margins.medium.l};
  padding-right: ${({ theme }) => theme.margins.medium.l};
	display: flex;
	flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    // width: auto;
      // margin-left: ${({ theme }) => theme.margins.small.s};
      // margin-right: ${({ theme }) => theme.margins.small.s};
    padding-left: ${({ theme }) => theme.margins.medium.s};
    padding-right: ${({ theme }) => theme.margins.medium.s};
  }
`;

const ButtonBlock = (props) => {
  const { title, text, button, link } = props;

  return (
    <Comp>
      <Spacer s="large" />
      <Text s="h2" as="h2" c="main01">
        {title}
      </Text>
      <Spacer s="small" />

      <Text s="medium">
        {text}
      </Text>
      <Spacer s="large" />

      <RoundButton data={{
        title: button,
        href: link
      }} type="pill" style={{ alignSelf: "center" }} />
      {/* <PillButton onClick={onClick}>
        <Text s={"buttonSmall"} as="span" w="inherit">
          {button}
        </Text>
      </PillButton> */}
      <Spacer s="large" />
    </Comp>
  );
};

export default ButtonBlock;