import React from "react";
import styled from "styled-components";
import SVG from "./SVG";

const Svg = styled(SVG)`
  height: 1.25rem;
	width: 1.25rem;
  color: inherit;
`;

export const Plus = ({ minus }) => {
	return (
		<Svg viewBox="0 0 21 20">
			{!minus ? <line x1="10.5" y1="18" x2="10.5" y2="2" stroke="currentColor" strokeWidth="4" strokeLinecap="round" /> : null}
			<line x1="2.5" y1="10" x2="18.5" y2="10" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
		</Svg>
	);
};
