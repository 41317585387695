import { graphql } from 'gatsby';
import React from 'react';
import styled from "styled-components";
import Tag from '../_global/Tag';
import Section from '../_global/layout/Section';
import Spacer from '../_global/layout/Spacer';

const Comp = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const VideoTags = ({ categories, category, setCategory, allCategories }) => {

    // console.log(allCategories)

    const tags = categories ? categories.filter(i => !i.all).map((item, index) => {
        return (
            <React.Fragment key={index}>

                <Tag clickable onClick={() => setCategory(item._id)} selected={category === item._id}>
                    {item.title}
                </Tag>
                {index < categories.length - 1 ? <Spacer s="small" /> : null}
            </React.Fragment>)
    }) : null

    return (
        <Section m="medium">
            <Comp>
                <Tag clickable onClick={() => setCategory(-1)} selected={category === -1}>
                    {allCategories.find(i => i.all).title}
                </Tag>
                <Spacer s="small" />

                {tags}
            </Comp>
        </Section>
    );
};

export default VideoTags;

export const query = graphql`
    fragment VideoCategoryFragment on SanityVideoCategory {
        _id
        text {
          _type
          _rawFr
        }
        title {
          _type
          fr
        }
        all
    }
`;