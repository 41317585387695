import React, { useState } from 'react';
import localize from '../components/_global/localization/localize';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/_global/layout/Layout';
import PageHeader from '../components/_global/layout/PageHeader';
import Section from '../components/_global/layout/Section';
import Spacer from '../components/_global/layout/Spacer';
import Block from '../components/_global/editorial/Block';
import NewsletterSection from '../components/_global/NewsletterSection';
import ButtonBlock from '../components/_global/ButtonBlock';
import Question from '../components/11_questions/Question';
import VideoTags from '../components/10_video/VideoTags';
import { removeDuplicateObjects } from '../utils/utils';

const Questions = ({ data }) => {
  const { page, questions: { nodes: questions } } = data;
  const allCategories = data.allSanityQuestionCategory.nodes;
  const cats = questions.map(item => {
    return item.tags
  }).flat();
  const categories = removeDuplicateObjects(cats, "_id")

  const [category, setCategory] = useState(-1);
  const filteredQuestions = category !== -1 ? questions.filter(i => i.tags.find(i => i._id === category)) : questions


  // console.log(categories)

  const theQuestions = filteredQuestions.map((item, index) => {
    return (
      <Question key={index} data={item} answerTitle={page.answerTitle} />
    )
  })

  return (
    <Layout title={page.common.title}>
      <PageHeader title={page.pageTitle} image={page.splashImage} text={page.splashTitle} />


      <Section m="medium">
        <Block data={page.text} />
        <Spacer s="large" />
      </Section>


      <Section m="small">
        <ButtonBlock
          title={page.focusTitle}
          text={page.focusText}
          button={page.focusButton}
          link='questions/ask'
        />
      </Section>
      <Spacer s="large" />


      <VideoTags categories={categories} category={category} setCategory={setCategory} allCategories={allCategories} />

      <Spacer s="large" />

      <Section m="small">
        {theQuestions}
      </Section>

      <Spacer s="large" />
    </Layout>
  );
};

export default localize(Questions);

export const query = graphql`
    {
        page: sanityQuestions {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            pageTitle {
              _type
              fr
            }
            splashImage
            {
                ...
                ImageFragment
            }
            splashTitle {
              _type
              fr
            }
            text {
                ...RegularBlockFragment
            }
            focusTitle {
              _type
              fr
            }
            focusText {
              _type
              fr
            }
            focusButton {
              _type
              fr
            }
            answerTitle {
              _type
              fr
            }
            seeMoreButton {
              _type
              fr
            }
        }
        questions: allSanityQuestion(sort: {fields: _createdAt, order: DESC}) {
          nodes {
            tags {
              title {
                _type
                fr
              }
              _id
            }
            question {
              ...CompleteBlockFragment
            }
            answer {
              ...CompleteBlockFragment
            }
            title {
              _type
              fr
            }
          }
        }
        allSanityQuestionCategory(sort: {fields: title___fr, order: ASC}) {
            nodes {
              _id
              title {
                _type
                fr
              }
              all
            }
        }
    }
`;