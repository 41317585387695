import React from "react";
import styled from "styled-components";
import Text from "./editorial/Text";

const Comp = styled(Text)`
  background-color: ${p => p.selected ? p.theme.colors.background : p.theme.colors.main01};
  color: ${p => p.selected ? p.theme.colors.main01 : p.theme.colors.background};
  width: fit-content;
  padding: 1rem;
  border-radius: 500px;
  cursor: ${p => p.clickable ? "pointer" : "default"};
  border: 2px solid ${({ theme }) => theme.colors.main01};
  margin-bottom: 1rem;

  :hover {
    background-color: ${p => !p.clickable && !p.inactive ? p.theme.colors.main01 : p.theme.colors.background};
    color: ${p => !p.clickable && !p.inactive ? p.theme.colors.background : p.theme.colors.main01};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    padding: 0.5rem 1rem;
  }
`;

const Tag = (props) => {
  const { children, clickable, onClick, selected, inactive } = props;
  return (
    <Comp s="smaller" clickable={clickable} inactive={inactive} onClick={onClick} selected={selected} {...props}>
      {
        children
      }
    </Comp>
  );
};

export default Tag;