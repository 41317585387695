import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import Text from '../_global/editorial/Text';
import Block from '../_global/editorial/Block';
import { Plus } from '../_svg/Plus';
import Tag from '../_global/Tag';
import Spacer from '../_global/layout/Spacer';
import { gsap } from "gsap";


const PlusContainer = styled.div`
    color: ${({ theme }) => theme.colors.main02};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0.1;
    min-width: 4rem;
    cursor: pointer;
    /* background-color: salmon; */

    /* :hover {
        color: ${({ theme }) => theme.colors.contrast};
    } */
`

const Title = styled(Text)`
`

const Comp = styled.div`
    border: 3px solid ${p => p.open ? p.theme.colors.contrast : p.theme.colors.main01};
    border-radius: 25px;
    padding: 2rem 5.5rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    background-color: ${p => p.open ? p.theme.colors.titanWhite : p.theme.colors.background};
    transition: background-color 0.5s;

    ${PlusContainer} {
        color: ${p => p.open ? p.theme.colors.contrast : p.theme.colors.main02};
    }

    :hover {  
        border: 3px solid ${({ theme }) => theme.colors.contrast};
    }

    &:hover ${PlusContainer} {
        color: ${({ theme }) => theme.colors.contrast};
    }

    /* &:hover ${Title} {
        color: ${({ theme }) => theme.colors.contrast};
    } */

    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
        padding: 1rem 2rem;
        flex-direction: column;
    }
`

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const TheQuestion = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    height: 8rem;
    /* flex: 1; */
    /* background-color: palegoldenrod; */
`

const TheTag = styled(Tag)`
    margin-bottom: 0;
    cursor: pointer;
`

const TagsContainer = styled.div`
    display:flex ;
`

const Question = ({ data, answerTitle }) => {
    const { title, question, answer, tags } = data
    const [open, setOpen] = useState(false)
    const questionRef = useRef()

    useEffect(() => {
        gsap.to(questionRef.current, {
            height: open ? 'auto' : '8rem',
            duration: 0.5,
            ease: "power3.inOut"
        })
    }, [open])

    const theTags = tags.map((item, index) => {
        return (
            <TheTag key={index} selected={true} inactive={true}>{item.title}</TheTag>
        )
    })

    return (
        <Comp onClick={() => setOpen(!open)} open={open}>

            <QuestionContainer>
                <TheQuestion ref={questionRef}>
                    <Title s="medium" w="bold" c="main01">
                        {title}
                    </Title>
                    <Block data={question} />
                    <Spacer s="medium" />
                    <Title s="medium" w="bold" c="main01">
                        {answerTitle}
                    </Title>
                    <Block data={answer} />
                </TheQuestion>

                <Spacer s="medium" />

                <TagsContainer>
                    {theTags}
                </TagsContainer>
            </QuestionContainer>

            <PlusContainer>
                <Plus minus={open} />
            </PlusContainer>
        </Comp>
    );
};

export default Question;